html,
body {
  font-size: 16px;
  margin: 0;
}

a {
  text-decoration: none;
}

:root {
  --placeholder-background: #f0f2f5;
  --hover-overlay: rgba(228, 230, 235, 0.6);
  --loading-overlay: rgba(255, 255, 255, 0.6);
  --modal-overlay: rgba(18, 44, 90, 0.45);

  --docker-preview-background: rgb(40, 42, 54);

  --divider: #e0e0e0;
  --brand: #ffe04a;
  --accent: #40a7ff;
  --warning: #ffba00;
  --error: #f24956;
  --bullet-accent: #31bfaf;
  --success: #00c107;
  --success-inverted: white;
  --purple: #821ce3;
  --yellow: #feb113;

  --nav-background: #fafbfc;
  --surface-background: white;

  --primary-color-button: white;
  --secondary-color-button: #40a7ff;
  --lite-color-button: #00334d;

  --pill-background: #e4e6eb;

  --primary-background-button: #40a7ff;
  --secondary-background-button: #e4e6eb;
  --lite-background-button: #f0f2f5;

  --section-header-background: #f0f2f5;

  --primary-background-icon-button: white;
  --secondary-background-icon-button: #e4e6eb;

  --primary-font-color: #3f5763;
  --secondary-font-color: #6a6a6a;
  --default-font-color: #838383;
  --placeholder-font-color: #838383;

  --input-color: #3f5763;
  --input-error-border: #f24956;
  --input-blur-border: #e0e0e0;
  --input-focus-border: #40a7ff;

  --fast-input-color: #00334d;
  --fast-input-background: #e4e6eb;

  --checkbox-border: #e0e0e0;

  --primary-icon-color: #3f5763;
  --secondary-icon-color: #838383;
  --lite-icon-color: #b1bcc1;

  --logo-icon-color: #00334d;

  --spinner-stroke: #40a7ff;

  --shadow-1: rgba(0, 0, 0, 0.1);
  --shadow-2: rgba(0, 0, 0, 0.2);
  --shadow-5: rgba(0, 0, 0, 0.5);

  --shadow-1-composite: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --shadow-2-composite: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  --shadow-3-composite: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  --shadow-4-composite: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  --shadow-5-composite: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

  --shadow-inset: rgba(255, 255, 255, 0.5);

  --font-family-apple: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  --font-family-code: ui-monospace, Menlo, Consolas, Monaco, monospace;
  --font-family-default: Helvetica, Arial, sans-serif;
  --font-family-segoe: Segoe UI Historic, Segoe UI, Helvetica, Arial, sans-serif;

  --fds-soft: cubic-bezier(0.08, 0.52, 0.52, 1);
  --fds-animation-enter-exit-in: cubic-bezier(0.14, 1, 0.34, 1);
  --fds-animation-enter-exit-out: cubic-bezier(0.45, 0.1, 0.2, 1);
  --fds-animation-swap-shuffle-in: cubic-bezier(0.14, 1, 0.34, 1);
  --fds-animation-swap-shuffle-out: cubic-bezier(0.45, 0.1, 0.2, 1);
  --fds-animation-move-in: cubic-bezier(0.17, 0.17, 0, 1);
  --fds-animation-move-out: cubic-bezier(0.17, 0.17, 0, 1);
  --fds-animation-expand-collapse-in: cubic-bezier(0.17, 0.17, 0, 1);
  --fds-animation-expand-collapse-out: cubic-bezier(0.17, 0.17, 0, 1);
  --fds-animation-passive-move-in: cubic-bezier(0.5, 0, 0.1, 1);
  --fds-animation-passive-move-out: cubic-bezier(0.5, 0, 0.1, 1);
  --fds-animation-quick-move-in: cubic-bezier(0.1, 0.9, 0.2, 1);
  --fds-animation-quick-move-out: cubic-bezier(0.1, 0.9, 0.2, 1);
  --fds-animation-fade-in: cubic-bezier(0, 0, 1, 1);
  --fds-animation-fade-out: cubic-bezier(0, 0, 1, 1);

  --fds-fast: 200ms;
  --fds-duration-extra-extra-short-in: 100ms;
  --fds-duration-extra-extra-short-out: 100ms;
  --fds-duration-extra-short-in: 200ms;
  --fds-duration-extra-short-out: 150ms;
  --fds-duration-short-in: 280ms;
  --fds-duration-short-out: 200ms;
  --fds-duration-medium-in: 400ms;
  --fds-duration-medium-out: 350ms;
  --fds-duration-long-in: 500ms;
  --fds-duration-long-out: 350ms;
  --fds-duration-extra-long-in: 1000ms;
  --fds-duration-extra-long-out: 1000ms;
  --fds-duration-none: 0ms;
}

:root .dark-theme {
  ---hover-overlay: rgba(0, 0, 0, 0.1);

  --surface-background: white;

  --primary-color-button: white;
  --secondary-color-button: #40a7ff;

  --primary-background-button: #40a7ff;
  --secondary-background-button: #e4e6eb;

  --primary-font-color: #3f5763;
  --default-font-color: #838383;
  --placeholder-font-color: #838383;

  --logo-icon-color: #00334d;

  --shadow-1: rgba(0, 0, 0, 0.1);
  --shadow-2: rgba(0, 0, 0, 0.2);
  --shadow-5: rgba(0, 0, 0, 0.5);

  --shadow-inset: rgba(255, 255, 255, 0.05);

  --font-family-apple: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  --font-family-code: ui-monospace, Menlo, Consolas, Monaco, monospace;
  --font-family-default: Helvetica, Arial, sans-serif;
  --font-family-segoe: Segoe UI Historic, Segoe UI, Helvetica, Arial, sans-serif;

  --fds-soft: cubic-bezier(0.08, 0.52, 0.52, 1);
  --fds-animation-enter-exit-in: cubic-bezier(0.14, 1, 0.34, 1);
  --fds-animation-enter-exit-out: cubic-bezier(0.45, 0.1, 0.2, 1);
  --fds-animation-swap-shuffle-in: cubic-bezier(0.14, 1, 0.34, 1);
  --fds-animation-swap-shuffle-out: cubic-bezier(0.45, 0.1, 0.2, 1);
  --fds-animation-move-in: cubic-bezier(0.17, 0.17, 0, 1);
  --fds-animation-move-out: cubic-bezier(0.17, 0.17, 0, 1);
  --fds-animation-expand-collapse-in: cubic-bezier(0.17, 0.17, 0, 1);
  --fds-animation-expand-collapse-out: cubic-bezier(0.17, 0.17, 0, 1);
  --fds-animation-passive-move-in: cubic-bezier(0.5, 0, 0.1, 1);
  --fds-animation-passive-move-out: cubic-bezier(0.5, 0, 0.1, 1);
  --fds-animation-quick-move-in: cubic-bezier(0.1, 0.9, 0.2, 1);
  --fds-animation-quick-move-out: cubic-bezier(0.1, 0.9, 0.2, 1);
  --fds-animation-fade-in: cubic-bezier(0, 0, 1, 1);
  --fds-animation-fade-out: cubic-bezier(0, 0, 1, 1);

  --fds-fast: 200ms;
  --fds-duration-extra-extra-short-in: 100ms;
  --fds-duration-extra-extra-short-out: 100ms;
  --fds-duration-extra-short-in: 200ms;
  --fds-duration-extra-short-out: 150ms;
  --fds-duration-short-in: 280ms;
  --fds-duration-short-out: 200ms;
  --fds-duration-medium-in: 400ms;
  --fds-duration-medium-out: 350ms;
  --fds-duration-long-in: 500ms;
  --fds-duration-long-out: 350ms;
  --fds-duration-extra-long-in: 1000ms;
  --fds-duration-extra-long-out: 1000ms;
  --fds-duration-none: 0ms;
}
